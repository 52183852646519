var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expense-report",class:_vm.reportListClass},[_c('Banner',[_c('div',{staticClass:"banner-content"},[_c('MeepIconNotes',{staticClass:"banner-content__icon"}),_c('span',{staticClass:"banner-content__text"},[_vm._v(_vm._s(_vm.$t("menu.expense-reports")))])],1)]),_c('PageHeader',{attrs:{"has-actions":_vm.hasAction,"has-back":_vm.$route.params.companyId ? true : false,"title":_vm.headerTitle},on:{"back":_vm.goBack}},[_c('div',{staticClass:"page-header__extra-actions"},[(!_vm.isClient)?[_c('div',{staticClass:"page-header__extra-actions-buttons"},[(_vm.linkToNoteFolder)?_c('md-button',{staticClass:"md-raised",class:{
              'md-primary': !_vm.isJEP,
              'md-alternate': _vm.isJEP,
            },attrs:{"to":_vm.linkToNoteFolder}},[_vm._v(" "+_vm._s(_vm.$t("expense-notes.to-cloud"))+" ")]):_vm._e(),(_vm.selectedReports.length !== 0)?_c('md-button',{staticClass:"md-raised",class:{
              'md-primary': !_vm.isJEP,
              'md-alternate': _vm.isJEP,
            },on:{"click":_vm.onPreview}},[_vm._v(" "+_vm._s(_vm.previewButtonTitle)+" ")]):_vm._e(),(_vm.$route.params.companyId && !_vm.isPayMode)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
              'md-primary': !_vm.isJEP,
              'md-alternate': _vm.isJEP,
            },on:{"click":_vm.onClickQuestion}},[_c('MeepIconCircleQuestion',{staticClass:"app-icon"})],1):_vm._e()],1),(_vm.showText)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.helpText)}}):_vm._e()]:[_c('div',{staticClass:"page-header__extra-actions-buttons"},[(_vm.hasUnpaid)?_c('md-button',{staticClass:"md-raised",class:{
              'md-primary': !_vm.isJEP,
              'md-alternate': _vm.isJEP,
            },on:{"click":_vm.onShowPay}},[_vm._v(" "+_vm._s(_vm.showPayAllButton ? _vm.$t("expense-notes.client-pay") : _vm.$t("expense-notes.employees.client-pay"))+" ")]):_vm._e()],1)]],2)]),_c('div',{staticClass:"expense-report-content page-layout"},[(!_vm.isPayMode)?_c('div',{staticClass:"expense-report__nav-bar"},[_c('div',{staticClass:"expense-report__nav-bar-client"},[_c('md-button',{attrs:{"to":"/dashboard/expense-reports/"}},[_vm._v(" "+_vm._s(_vm.totalCompanies)+" ")]),(_vm.company)?_c('div',{staticClass:"expense-report__nav-bar__next"},[_c('md-icon',[_vm._v("navigate_next")]),_c('md-button',{staticClass:"md-note-active",on:{"click":_vm.goBack}},[_vm._v(" "+_vm._s(_vm.pathText)+" ")])],1):_vm._e()],1)]):_vm._e(),(_vm.loading)?_c('LoadingCard'):_vm._e(),(_vm.showListCompanies)?_c('div',{staticClass:"expense-report__companies"},_vm._l((_vm.companies),function(_company,index){return _c('md-toolbar',{key:index},[_c('md-button',{attrs:{"to":'/dashboard/expense-reports/' + _company.id}},[_c('MeepIconSettingCompanies',{staticClass:"app-icon"}),_c('h3',{staticClass:"md-title"},[_vm._v(_vm._s(_company.name))]),_c('p',{staticClass:"expense-report__companies-siren"},[_vm._v(_vm._s(_company.siren))])],1)],1)}),1):_vm._e(),(_vm.previewData.length === 0 && !_vm.isPayMode)?_c('div',[(_vm.showListReports)?_c('sortable-list',{class:_vm.isClient ? 'list-client' : '',attrs:{"hasPagination":true,"items":_vm.userReports,"link":`${_vm.$route.path}/view/`,"page-size":5,"parameters":_vm.reportParameters,"default-sort":"authorName","not-found-label":"Aucun élément trouvé","selectable":"multiple"},on:{"selected":_vm.onSelect,"item-click":_vm.onItemClick}}):_vm._e()],1):_c('div',{attrs:{"id":"xlsx-table"}}),(_vm.isPayMode)?_c('ExpenseReportPay',{attrs:{"reports":_vm.selectedUsers,"type":"company"},on:{"back":_vm.deleteSelectedReport}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }