<template>
  <div class="expense-report" :class="reportListClass">
    <Banner>
      <div class="banner-content">
        <MeepIconNotes class="banner-content__icon" />
        <span class="banner-content__text">{{
          $t("menu.expense-reports")
        }}</span>
      </div>
    </Banner>

    <PageHeader
      :has-actions="hasAction"
      :has-back="$route.params.companyId ? true : false"
      :title="headerTitle"
      @back="goBack"
    >
      <div class="page-header__extra-actions">
        <template v-if="!isClient">
          <div class="page-header__extra-actions-buttons">
            <md-button
              v-if="linkToNoteFolder"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              :to="linkToNoteFolder"
              class="md-raised"
            >
              {{ $t("expense-notes.to-cloud") }}
            </md-button>

            <md-button
              v-if="selectedReports.length !== 0"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onPreview"
            >
              {{ previewButtonTitle }}
            </md-button>
            <md-button
              v-if="$route.params.companyId && !isPayMode"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised header-action header-action-icon"
              @click="onClickQuestion"
            >
              <MeepIconCircleQuestion class="app-icon" />
            </md-button>
          </div>

          <div v-if="showText" class="text" v-html="helpText"></div>
        </template>
        <template v-else>
          <div class="page-header__extra-actions-buttons">
            <!-- Show pay button only when has unpaid -->
            <md-button
              v-if="hasUnpaid"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onShowPay"
            >
              {{
                showPayAllButton
                  ? $t("expense-notes.client-pay")
                  : $t("expense-notes.employees.client-pay")
              }}
            </md-button>
          </div>
        </template>
      </div>
    </PageHeader>
    <div class="expense-report-content page-layout">
      <div v-if="!isPayMode" class="expense-report__nav-bar">
        <div class="expense-report__nav-bar-client">
          <md-button to="/dashboard/expense-reports/">
            {{ totalCompanies }}
          </md-button>
          <div v-if="company" class="expense-report__nav-bar__next">
            <md-icon>navigate_next</md-icon>

            <md-button class="md-note-active" @click="goBack">
              {{ pathText }}
            </md-button>
          </div>
        </div>
      </div>

      <LoadingCard v-if="loading" />
      <div v-if="showListCompanies" class="expense-report__companies">
        <md-toolbar v-for="(_company, index) in companies" :key="index">
          <md-button :to="'/dashboard/expense-reports/' + _company.id">
            <MeepIconSettingCompanies class="app-icon" />
            <h3 class="md-title">{{ _company.name }}</h3>
            <p class="expense-report__companies-siren">{{ _company.siren }}</p>
          </md-button>
        </md-toolbar>
      </div>

      <div v-if="previewData.length === 0 && !isPayMode">
        <sortable-list
          v-if="showListReports"
          :class="isClient ? 'list-client' : ''"
          :hasPagination="true"
          :items="userReports"
          :link="`${$route.path}/view/`"
          :page-size="5"
          :parameters="reportParameters"
          default-sort="authorName"
          not-found-label="Aucun élément trouvé"
          selectable="multiple"
          @selected="onSelect"
          @item-click="onItemClick"
        />
      </div>
      <div v-else id="xlsx-table" />

      <ExpenseReportPay
        v-if="isPayMode"
        :reports="selectedUsers"
        type="company"
        @back="deleteSelectedReport"
      />
    </div>
  </div>
</template>

<script>
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import reportModel from "@/model/expense-reports";
import expenseReports from "@/model/expense-reports";
import { mapGetters } from "vuex";
import _ from "lodash";
import canvasDatagrid from "canvas-datagrid";
import ExpenseReportPay from "./expense-report-pay";
import Banner from "@/components/Banner";
import MeepIconNotes from "@/components/icons/MeepIconNotes.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import MeepIconCircleQuestion from "@/components/icons/MeepIconCircleQuestion.vue";

export default {
  name: "ExpenseReportListCompanies",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    ExpenseReportPay,
    MeepIconSettingCompanies,
    MeepIconNotes,
    MeepIconCircleQuestion,
  },

  data() {
    return {
      hasUnpaid: false,
      companies: [],
      companiesLength: 0,
      company: null,
      companyId: null,
      loading: true,
      userReports: [],
      selectedReports: [],
      selectedUsers: [],
      showText: false,
      previewData: [],
      showEditModal: false,
      ids: [],
      isPayMode: false,
      tabs: [
        {
          id: "tab-all",
          label: "Tous",
        },
      ],
      currentTab: "",
      linkToNoteFolder: "",
      today: new Date(),
    };
  },

  computed: {
    ...mapGetters(["isCollab", "isClient", "isJEP", "isGC"]),
    reportListClass() {
      return { "report-list-gc": this.isGC };
    },

    reportParameters() {
      const defaultParams = [
        {
          name: this.$t("expense-notes.default-params.author-name"),
          key: "authorName",
          class: "primary-item",
        },
        {
          name: this.$t("expense-notes.default-params.count"),
          key: "count",
        },
        {
          name: this.$t("expense-notes.default-params.total-month-tvc"),
          key: "totalMonthTVC",
          format: this.$$filters.formatNumber,
        },
      ];

      const clientParams = [
        {
          name: this.$t("expense-notes.default-params.author-name"),
          key: "authorName",
          class: "primary-item",
        },
        {
          name: this.$t("expense-notes.client-params.companies-name"),
          key: "companyName",
        },
        {
          name: this.$t("expense-notes.client-params.unexported-reports"),
          key: "waitingReports",
        },
        {
          name: this.$t("expense-notes.client-params.unpaid-reports"),
          key: "unPaidReports",
        },
        {
          name: this.$t("expense-notes.client-params.count"),
          key: "count",
        },
        {
          name: this.$t("expense-notes.client-params.total-month-tvc"),
          key: "totalMonthTVC",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.client-params.total-unpaid-tvc"),
          key: "totalUnpaidTVC",
          format: this.$$filters.formatNumber,
        },
      ];

      return this.isClient ? clientParams : defaultParams;
    },

    pathText() {
      return `${this.company.name} (${this.userReports.length})`;
    },

    helpText() {
      if (this.previewData.length > 0) {
        return this.$t("expense-notes.employees.preview-text");
      }
      return this.$t("expense-notes.employees.question-sub-text");
    },

    showPayAllButton() {
      return (
        !this.isPayMode && this.hasUnpaid && this.selectedUsers.length === 0
      );
    },

    hasAction() {
      return (
        this.isClient ||
        (this.isCollab && this.isSingleReportSelected) ||
        this.isMultipleReportsSelected
      );
    },

    selectedReport() {
      return _.get(this.selectedReports, "[0]", null);
    },

    isMultipleReportsSelected() {
      return (
        Array.isArray(this.selectedReports) && this.selectedReports.length > 1
      );
    },

    isSingleReportSelected() {
      return (
        Array.isArray(this.selectedReports) && this.selectedReports.length === 1
      );
    },

    showListCompanies() {
      return !this.loading && !this.company;
    },

    showListReports() {
      return !this.loading && !this.showListCompanies;
    },

    totalCompanies() {
      return this.$t("expense-notes.all-client", {
        number: this.companiesLength,
      });
    },

    headerTitle() {
      if (this.isPayMode) {
        return this.$t("expense-notes.pay-title", {
          month: this.$$filters.formatFullMonth(new Date().getMonth()),
          year: new Date().getFullYear(),
        });
      }

      if (this.$route.params.companyId) {
        return this.company ? this.company.name : "";
      } else return "";
    },

    previewButtonTitle() {
      if (this.previewData.length > 0) {
        return this.$t("expense-notes.employees.export");
      }
      return this.$t("expense-notes.export");
    },
  },

  watch: {
    $route: "loadData",
  },

  async mounted() {
    await this.loadData();
    const companies = await companiesModel.getAll();
    this.companiesLength = companies.length;
  },

  methods: {
    goBack() {
      if (this.previewData.length !== 0) {
        this.previewData = [];
        this.selectedReports = [];
        this.loadData();
      } else if (this.isPayMode) {
        this.selectedReports = [];
        this.isPayMode = false;
      } else {
        window.history.back();
      }
    },

    async onExport() {
      try {
        this.loading = true;
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.exportReports(ids);

        this.$toasted.global.AppSucces({
          message: "L'export s'est terminé avec succès",
        });

        // Remove exported reports
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id)
        );
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: "Une erreur est survenue, veuillez réessayer plus tard",
        });
      } finally {
        this.loading = false;
      }
    },

    async onRefuse() {
      try {
        this.loading = true;
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.refuse(ids);

        this.$toasted.global.AppSucces({
          message: "Action terminé avec succès",
        });

        // Remove exported reports
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id)
        );
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: "Une erreur est survenue, veuillez réessayer plus tard",
        });
      } finally {
        this.loading = false;
      }
    },

    async onPreview() {
      if (this.previewData.length !== 0) {
        await this.onExport();
        this.goBack();
      } else {
        this.previewData = await expenseReports.getExcelData(this.ids);

        await this.$nextTick();

        const grid = canvasDatagrid({
          parentNode: document.getElementById("xlsx-table"),
          data: this.previewData,
        });

        grid.style.width = "100%";
        grid.style.height = "calc(100vh - 350px)";
        grid.style.marginTop = "80px";
      }
    },

    async loadData() {
      try {
        this.loading = true;
        this.companyId = this.$route.params.companyId;

        this.currentTab = "tab-all";
        this.selectedReports = [];
        this.isPayMode = false;
        this.previewData = [];

        if (!this.companyId) {
          const companies = await companiesModel.getAll();
          this.company = null;
          this.userReports = [];
          this.companies = companies.map(company => {
            // Link to go
            company.to = `/dashboard/expense-reports/${company.id}`;
            return company;
          });
          this.linkToNoteFolder = "";
        } else {
          const { items: reports } = await reportModel.getExpenseReportStats({
            companyId: this.companyId,
            type: "multi",
            getAll: true,
          });

          this.company = await companiesModel.get(this.companyId);

          this.linkToNoteFolder = await companiesModel.getLinkToNoteFolder(
            this.companyId
          );

          this.userReports = reports.map(report => {
            report.id = report.userId;
            report.companyName = this.company.name;

            report.is_protected =
              (this.isClient && report.unPaidReports === 0) ||
              (this.isCollab && report.count === 0);

            if (!report.is_protected && !this.hasUnpaid) {
              this.hasUnpaid = true;
            }
            return report;
          });
        }
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.loading = false;
      }
    },

    onSelect(userArray) {
      this.selectedUsers = [...userArray];
      this.companies = [];
      this.selectedReports = [];
      this.ids = [];

      userArray.map(item => {
        item.reports.map(report => {
          this.selectedReports.push(report);
          this.ids.push(report.id);
        });
      });
    },

    onItemClick({ item }) {
      this.$router.push("/dashboard/cloud/" + item.fileId);
    },

    onClickQuestion() {
      this.showText = !this.showText;
    },

    onShowPay() {
      if (this.selectedUsers.length === 0) {
        // Select all user if pay all button clicked
        this.selectedUsers = this.userReports.filter(
          userReport => userReport.count > 0
        );
      }

      this.isPayMode = true;
    },

    deleteSelectedReport() {
      this.isPayMode = false;
      this.selectedReports = [];
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .expense-report {
    .banner{
      margin-bottom: toRem(27);
    }
    .page-header {
      position: relative;
      .page-header__tabs-search,
      .page-header__title{
        display: none;
      }
      .md-tabs-navigation {
        .md-button {
          height: 60px;
          font-family: var(--font-bold);
          font-size: 16px;
          &:nth-child(odd) {
            color: var(--bg-primary);
          }
        }
      }

      &__extra-actions {
        position: absolute;
        right: 30px;
        top: 0px;
        &-buttons {
          display: flex;
          justify-content: flex-end;
          .question-mark {
            min-width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }
        .text {
          height: 75px;
          width: 600px;
          font-size: 12px;
          line-height: 1.2;
          text-align: right;
          margin-top: 10px;
        }
      }
    }

    &__card {
      margin: 32px auto;
      padding: 16px;
      font-weight: bold;
    }

    &__nav-bar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .md-button {
        height: 44px;
        background-color: transparent;
        border-bottom: 2px solid var(--bg-primary);
        font-family: var(--font-bold);
        text-transform: none;
        font-size: toRem(17);
        @include for-lg{
          font-size: toRem(20);
        }
        &.md-note-active {
          color: var(--text-secondary);
        }
      }

      &__next {
        display: flex;
        align-content: center;
      }

      &-client {
        display: flex;
        flex-direction: row;
        .md-button {
          border-bottom: none;
          font-family: var(--font-extrabold);
          letter-spacing: -0.5px;
          &:first-child {
            margin-left: 0px;
          }
        }
      }
    }

    &__companies {
      margin-top: 5px;
      .md-title{
        font-size: toRem(13);
        @include for-lg{
          font-size: toRem(20);
        }
      }
      .md-toolbar {
        font-family: var(--font-bold);
        box-shadow: none;
        padding: 0;
        height: 63px;
        border-bottom: 1px solid var(--bg-secondary, #000);
        @include for-lg{
          height: 95px;
        }
        .md-button {
          width: 100%;
          height: 100%;
          .md-ripple {
            padding: 0;
            .md-button-content {
              width: 100%;
              display: flex;
              align-items: center;

              .app-icon {
                width: 25px;
                height: 25px;
                margin: 0 25px;
              }
            }
            .md-tittle {
              margin: 0;
            }
          }
        }

        &:first-child {
          border-top-left-radius: 11px;
          border-top-right-radius: 11px;
          .md-button {
            &:hover {
              border-top-left-radius: 11px;
              border-top-right-radius: 11px;
            }
          }
        }
      }

      &-siren {
        font-size: toRem(13);
        margin-left: 25px;
        font-family: var(--font);
        @include for-lg{
          font-size: toRem(20);
        }
      }
    }

    &__red-circle {
      width: 40px;
      height: 40px;
      margin: 0 30px 0 30px;
      background-color: var(--bg-primary);
      border-radius: 50%;
    }

    .sortable-list {
      padding: 0;
      @include for-lg {
        padding: toRem(32) 0;
      }

      .sortable-list__table.md-card.md-theme-default.md-table {
        padding: 0;
      }
      .md-sortable {
        .md-table-head-container {
          display: flex;
          align-items: center;
          .md-table-head-label {
            width: 100%;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;
            display: flex;
            align-items: center;
          }
        }
      }
      .md-table-head {
        .md-table-head-container {
          height: 41px;
          @include for-lg{
          height: 80px;}
        }
      }
    }

    .list-client {
      .md-sortable {
        .md-table-head-container {
          width: auto;
          height: 80px;
        }
      }
    }

    &-content {
      &.page-layout {
        height: 100%;
      }
    }
  }
  .report-list-gc {
    .expense-report__nav-bar {
      .md-button {
        font-family: var(--font-bold);
        text-transform: none;
        font-size: toRem(17);
        @include for-lg{
          font-size: toRem(20);
        }
        &.md-note-active {
          color: var(--primary);
        }
      }
    }
  }
}
</style>
